import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import SEO from '../components/seo';
import Layout from '../components/layout';
import mq from '../utils/mq';
import portraitURL from '../images/portrait.png';

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        tag: fieldValue
        count: totalCount
      }
    }
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;

  p {
    grid-column-start: span 2;
    text-align: justify;
    hyphens: auto;
    text-indent: 1.5rem;

    &.first {
      ${mq.bpw4} {
        text-indent: 0;
        grid-column-start: auto;
        align-self: center;
      }
    }
  }

  margin: 0 auto;
  max-width: 860px;
  height: 100%;
  padding: 1.45rem 1.0875rem;
`;

const Title = styled.h1`
  grid-column-start: span 2;
  margin-bottom: 2rem;
`;

const Section = styled.h2`
  grid-column-start: span 2;
`;

const Portrait = styled.img`
  grid-column-start: span 2;
  justify-self: center;

  width: 7.53rem;
  height: 7.53rem;
  float: left;
  margin-right: 1rem;
  margin-bottom: 1.45rem;

  ${mq.bpw4} {
    width: 12rem;
    height: 12rem;
    grid-column-start: auto;
  }
`;

export default props => {
  return (
    <Layout
      main={
        <Container lang="en">
          <SEO title="About me" keywords={['about', 'developer', 'bio']} />
          <Title>About me</Title>
          <Portrait src={portraitURL} alt="Me" />
          <p className="first">
            Hello! As you've pro&shy;ba&shy;bly guessed by now, I'm a
            soft&shy;ware de&shy;ve&shy;lo&shy;per.{' '}
            <span className="extra">
              But if that is not enough to quench your thirst for information,
              sit back, relax and read on as I try to give you a little more
              insight into who I am.
            </span>
          </p>
          <Section>A bit of history...</Section>
          <p lang="en">
            My career started in the early 2000s (way before being a nerd was
            considered 'cool'), and since then I had the opportunity to work
            with a lot of talented people in various tech stacks and
            different-sized projects. I'm a firm believer of "learning by
            doing", and there's nothing like a challenging task to keep the mind
            as sharp as it can be.
          </p>
          <p>
            Most of my early years were spent wor&shy;king on custom ERP systems
            for Desktop (Windows), with the occasional development using
            embedded sys&shy;tems. In the last de&shy;ca&shy;de, this changed to
            web de&shy;ve&shy;lop&shy;ment (APIs, SaaS, microservices) and, as
            expected, my skill set chan&shy;ged accordi&shy;ngly.
          </p>
          <p>
            I'm happy to say that the journey so far was a pretty good one, and
            helped me learn how to be flexible and adapt to different
            environments, people, and tech&shy;no&shy;lo&shy;gies.
          </p>
          <p>
            Currently, I'm working with the amazing folks at{' '}
            <a title="Luxor Technologies" href="https://www.luxor.tech/">
              Luxor Technologies
            </a>{' '}
            to make the Hashrate Index a reality.
          </p>
          <Section>Current projects</Section>
          <p>
            As Jack Torrance once wrote,{' '}
            <samp>"All work and no play makes Jack a dull boy"</samp>. It is a
            no-brainer that a ba&shy;lan&shy;ced work-life
            re&shy;la&shy;tion&shy;ship&nbsp;
            <strong>re&shy;qui&shy;res</strong> at least a bit of fun once in a
            while. And nothing is stopping you to have fun <strong>and</strong>{' '}
            be pro&shy;duc&shy;ti&shy;ve!
          </p>
          <p>
            When I find myself with this mindset, I like to work at one of my
            projects. Cur&shy;rent&shy;ly, I'm not working on anything particularly
            interesting, but I plan to shake things up this year.
          </p>
        </Container>
      }
    />
  );
};

/*
         <p>
            When I find myself with this mindset, I like to work at one of my
            projects. Cur&shy;rent&shy;ly, I'm giving more attention to (in no
            particular order):
          </p>
          <ul>
            <li>
              <a title="libcmd" href="https://github.com/ibraimgm/libcmd">
                libcmd
              </a>
            </li>
            <li>
              <a title="DWM fork" href="https://github.com/ibraimgm/dwm">
                My dwm fork
              </a>
            </li>
          </ul>
*/
